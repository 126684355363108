export default {
  parseOptions (options) {
    return {
      dropCallback: options.onDrop,
      highlightClass: options.highlightClass,
      isDisabled: Boolean(options.disabledIf)
    }
  },

  update (_, binding) {
    binding.def.opts = binding.def.parseOptions(binding.value)
  },

  bind (element, binding) {
    const def = binding.def
    def.opts = def.parseOptions(binding.value)
    def.enterCounter = 0
    def.onDragEnter = event => {
      if (def.opts.isDisabled) return
      if (!def.enterCounter) {
        element.classList.add(def.opts.highlightClass)
      }
      def.enterCounter += 1
    }
    def.onDragLeave = event => {
      if (def.opts.isDisabled) return
      def.enterCounter -= 1
      if (!def.enterCounter) {
        element.classList.remove(def.opts.highlightClass)
      }
    }
    def.preventStop = event => {
      if (def.opts.isDisabled) return
      event.preventDefault()
      event.stopPropagation()
    }
    def.onDrop = event => {
      if (def.opts.isDisabled) return
      const files = event.target.files || event.dataTransfer.files
      def.opts.dropCallback(files)
    }
    def.eventAssignments = {
      dragenter: [def.preventStop, def.onDragEnter],
      dragover: [def.preventStop],
      dragleave: [def.preventStop, def.onDragLeave],
      drop: [def.preventStop, def.onDragLeave, def.onDrop]
    }

    Object.entries(def.eventAssignments)
      .forEach(([event, listeners]) => listeners
        .forEach(listener => document
          .addEventListener(event, listener, false)
        )
      )
  },

  unbind (_, { def }) {
    Object.entries(def.eventAssignments)
      .forEach(([event, listeners]) => listeners
        .forEach(listener => document
          .removeEventListener(event, listener, false)
        )
      )
  }
}
