<template>
  <ui-select
    class="location-input"
    fill="frame"
    :value="value"
    :options="options"
    :placeholder="isLoaded
      ? placeholder || $t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')
      : $t('LOADING_MSG')
    "
    filter-method="none"
    :search-placeholder="$t('SEARCH_MSG')"
    :empty-message="$t('NO_LIST_MSG')"
    has-filter
    @filter="loadLocationsDebounced"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
      name="error"
      slot="error"
    />
  </ui-select>
</template>

<script>
import { UiSelect } from '@shelf.network/ui-kit'

import { mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'

import { sdk } from 'Services/shelfNetworkSdk'
import { Location } from 'Models/Location'

import uniqBy from 'lodash/uniqBy'
import debounce from 'lodash/debounce'

export default {
  name: 'select-location',
  components: { UiSelect },

  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },

  data () {
    return {
      locations: [],
      isLoaded: false,
    }
  },

  computed: {
    ...mapGetters({
      platform: `entities/user/${userGetters.PLATFORM}`,
    }),

    options () {
      return this.locations.map(item => {
        return {
          value: item.cityId,
          label: [item.cityName, item.regionName, item.countryName]
            .filter(val => val)
            .join(this.$t('COMMON.SYMBOLS.COMMA')),
        }
      })
    },
  },

  methods: {
    async loadLocations (search) {
      const locale = (this.$i18n || {}).locale
      if (!locale) return // cover case when the component destroyed

      const locations = []
      if (this.value) {
        const { data } = await sdk.identity.locations.getByPlatform({
          lang: locale,
          query: {
            filter: { 'city.id': this.value }
          }
        })
        locations.push(...data)
      }

      const { data } = await sdk.identity.locations.getByPlatform({
        lang: locale,
        query: {
          filter: {
            'city.name': search || undefined,
            cityQualified: true,
          }
        }
      })
      locations.push(...data)

      this.locations = uniqBy(locations, 'id')
        .map(item => new Location(item))
      this.isLoaded = true
    },

    loadLocationsDebounced: debounce(async function (search) {
      await this.loadLocations(search)
    }, 500),
  },
}
</script>

<i18n>
{
  "en": {
    "SEARCH_MSG": "Search location",
    "LOADING_MSG": "Loading…",
    "NO_LIST_MSG": "No locations matching the search"
  },
  "ka": {
    "SEARCH_MSG": "ლოკაციის ძებნა",
    "LOADING_MSG": "იტვირთება…",
    "NO_LIST_MSG": "არ მოიძებნა"
  },
  "ru": {
    "SEARCH_MSG": "Поиск местонахождения",
    "LOADING_MSG": "Загрузка…",
    "NO_LIST_MSG": "Мест соответствующих поисковому запросу не найдено"
  },
  "uk": {
    "SEARCH_MSG": "Пошук місця розташування",
    "LOADING_MSG": "Завантаження…",
    "NO_LIST_MSG": "Місць відповідних пошуковому запиту не знайдено"
  }
}
</i18n>
