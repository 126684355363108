<template>
  <div class="add-car__section-content">
    <div class="add-car__form-row">
      <ui-select
        class="add-car__form-field"
        fill="frame"
        :placeholder="$t('SALE_PERIOD')"
        :options="saleDurationOptions"
        :value="form.saleDuration"
        :is-error="Boolean(errors.saleDuration)"
        @input="setFormField({ field: 'saleDuration', value: $event })"
      >
        <span slot="error">
          {{ jsvLocalizeError(errors.saleDuration) }}
        </span>
      </ui-select>

      <price-input
        :value="form.saleB2CPrice"
        :is-error="Boolean(errors.saleB2CPrice)"
        :placeholder="$t('B2C_VEHICLE_PRICE')"
        @input="setFormField({ field: 'saleB2CPrice', value: $event })"
      >
        <span slot="error">
          {{ jsvLocalizeError(errors.saleB2CPrice) }}
        </span>
      </price-input>
    </div>

    <div
      class="add-car__form-row"
      v-if="$can($USER_CLAIMS.SHELF_AP_FEATURES)"
    >
      <price-input
        :value="form.saleB2BPrice"
        :is-error="Boolean(errors.saleB2BPrice)"
        :placeholder="$t('B2B_VEHICLE_PRICE')"
        @input="setFormField({ field: 'saleB2BPrice', value: $event })"
      >
        <span slot="error">
          {{ jsvLocalizeError(errors.saleB2BPrice) }}
        </span>
      </price-input>

      <price-input
        :value="form.saleB2CTotalPrice"
        :is-error="Boolean(errors.saleB2CTotalPrice)"
        :placeholder="$t('B2C_TOTAL_PRICE')"
        @input="setFormField({ field: 'saleB2CTotalPrice', value: $event })"
      >
        <span slot="error">
          {{ jsvLocalizeError(errors.saleB2CTotalPrice) }}
        </span>
      </price-input>
    </div>

    <div class="add-car__form-row">
      <ui-switch
        class="add-car__form-field"
        look="primary"
        :value="form.isOnRoad"
        @input="setIsOnRoad"
      >
        {{ $t('ON_THE_ROAD_LBL') }}
      </ui-switch>

      <ui-switch
        class="add-car__form-field"
        look="primary"
        :is-disabled="form.isOnRoad"
        :value="form.isPriceNegotiable"
        @input="setFormField({ field: 'isPriceNegotiable', value: $event })"
      >
        {{ $t('EXCHANGE_ALLOWED_LBL') }}
      </ui-switch>
    </div>
  </div>
</template>

<script>
import PriceInput from './PriceInput'
import { UiSelect, UiSwitch } from '@shelf.network/ui-kit'

import { mapGetters, mapMutations } from 'vuex'
import { getters, mutations } from '../store/types'
import { enumsGetters } from 'Store/entities/Enums/types'

import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

export default {
  name: 'add-car-direct-sale-params',
  components: {
    PriceInput,
    UiSelect,
    UiSwitch,
  },
  computed: {
    ...mapGetters('ui/add-car', {
      form: getters.form,
      errors: getters.errors,
    }),
    ...mapGetters('entities/enums', {
      saleDurationOptions: enumsGetters.SALE_PERIODS_AS_OPTIONS,
    })
  },
  methods: {
    ...mapMutations('ui/add-car', {
      setFormField: mutations.SET_FORM_FIELD,
      setIsOnRoad: mutations.SET_IS_ON_ROAD,
    }),

    jsvLocalizeError,
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/add-car-form";
</style>

<i18n>
{
  "en": {
    "B2B_VEHICLE_PRICE": "B2B Vehicle Price",
    "B2C_VEHICLE_PRICE": "B2C Vehicle Price",
    "B2C_TOTAL_PRICE": "B2C Total Price",
    "ON_THE_ROAD_LBL": "On the road",
    "SALE_PERIOD": "Sale period",
    "EXCHANGE_ALLOWED_LBL": "Exchange allowed"
  },
  "ka": {
    "B2B_VEHICLE_PRICE": "B2B-ის ფასი",
    "B2C_VEHICLE_PRICE": "B2C-ის ფასი",
    "B2C_TOTAL_PRICE": "B2C სრული ფასი",
    "ON_THE_ROAD_LBL": "გზაში",
    "SALE_PERIOD": "გაყიდვის პერიოდი",
    "EXCHANGE_ALLOWED_LBL": "გაცვლა შესაძლებელია"
  },
  "ru": {
    "B2B_VEHICLE_PRICE": "B2B Стоимость автомобиля",
    "B2C_VEHICLE_PRICE": "B2C Стоимость автомобиля",
    "B2C_TOTAL_PRICE": "B2C Итоговая цена",
    "ON_THE_ROAD_LBL": "В дороге",
    "SALE_PERIOD": "Период продажи",
    "EXCHANGE_ALLOWED_LBL": "Возможен обмен"
  },
  "uk": {
    "B2B_VEHICLE_PRICE": "B2B Вартість автомобіля",
    "B2C_VEHICLE_PRICE": "B2C Вартість автомобіля",
    "B2C_TOTAL_PRICE": "B2C Кінцева ціна",
    "ON_THE_ROAD_LBL": "В дорозі",
    "SALE_PERIOD": "Період продажу",
    "EXCHANGE_ALLOWED_LBL": "Можливий обмін"
  }
}
</i18n>
