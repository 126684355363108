<template>
  <div
    class="
      add-car__form-field
      add-car__form-field_combo
    "
  >
    <ui-text
      type="number"
      :min="0"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template slot="error">
        <slot name="error" />
      </template>
    </ui-text>

    <ui-select
      @input="setFormField({ field: 'currency', value: $event })"
      :value="form.currency"
      :options="currencyOptions"
    />
  </div>
</template>

<script>

import { UiText, UiSelect } from '@shelf.network/ui-kit'

import { mapGetters, mapMutations } from 'vuex'
import { getters, mutations } from '../store/types'
import { enumsGetters } from 'Store/entities/Enums/types'

export default {
  name: 'price-input',
  components: {
    UiText,
    UiSelect,
  },
  computed: {
    ...mapGetters('ui/add-car', {
      form: getters.form,
    }),
    ...mapGetters('entities/enums', {
      currencyOptions: enumsGetters.CURRENCIES_AS_OPTIONS,
    }),
  },
  methods: {
    ...mapMutations('ui/add-car', {
      setFormField: mutations.SET_FORM_FIELD,
    }),
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/add-car-form";
</style>
