<template>
  <div class="add-car__section-content">
    <div class="add-car__form-row">
      <price-input
        :value="form.engStartPrice"
        :is-error="Boolean(errors.engStartPrice)"
        :placeholder="$t('START_PRICE')"
        @input="setFormField({ field: 'engStartPrice', value: $event })"
      >
        <span slot="error">
          {{ jsvLocalizeError(errors.engStartPrice) }}
        </span>
      </price-input>

      <price-input
        :value="form.engBuyNowPrice"
        :is-error="Boolean(errors.engBuyNowPrice)"
        :placeholder="$t('BUY_NOW_PRICE_IF_APPLICABLE')"
        @input="setFormField({ field: 'engBuyNowPrice', value: $event })"
      >
        <span slot="error">
          {{ jsvLocalizeError(errors.engBuyNowPrice) }}
        </span>
      </price-input>
    </div>
    <div class="add-car__form-row">
      <price-input
        :value="form.engMinStep"
        :is-error="Boolean(errors.engMinStep)"
        :placeholder="$t('MIN_STEP')"
        @input="setFormField({ field: 'engMinStep', value: $event })"
      >
        <span slot="error">
          {{ jsvLocalizeError(errors.engMinStep) }}
        </span>
      </price-input>
      <price-input
        :value="form.engMaxStep"
        :is-error="Boolean(errors.engMaxStep)"
        :placeholder="$t('MAX_STEP')"
        @input="setFormField({ field: 'engMaxStep', value: $event })"
      >
        <span slot="error">
          {{ jsvLocalizeError(errors.engMaxStep) }}
        </span>
      </price-input>
    </div>

    <div class="add-car__form-row">
      <price-input
        :value="form.engDeposit"
        :is-error="Boolean(errors.engDeposit)"
        :placeholder="$t('DEPOSIT_AMOUNT')"
        @input="setFormField({ field: 'engDeposit', value: $event })"
      >
        <span slot="error">
          {{ jsvLocalizeError(errors.engDeposit) }}
        </span>
      </price-input>

      <date-input
        class="add-car__form-field"
        :value="form.engEndTime"
        :is-error="Boolean(errors.engEndTime)"
        :placeholder="$t('AUCTION_END_TIME')"
        @input="setFormField({ field: 'engEndTime', value: $event })"
      >
        <span slot="error">
          {{ jsvLocalizeError(errors.engEndTime) }}
        </span>
      </date-input>
    </div>
  </div>
</template>

<script>
import PriceInput from './PriceInput'
import DateInput from './DateInput'
import { mapGetters, mapMutations } from 'vuex'
import { getters, mutations } from '../store/types'
import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

export default {
  name: 'add-car-eng-auction-params',
  components: {
    DateInput,
    PriceInput,
  },
  computed: {
    ...mapGetters('ui/add-car', {
      form: getters.form,
      errors: getters.errors,
    }),
  },
  methods: {
    ...mapMutations('ui/add-car', {
      setFormField: mutations.SET_FORM_FIELD,
    }),

    jsvLocalizeError,
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/add-car-form";
</style>

<i18n>
{
  "en": {
    "SALE_PERIOD": "Sale period",
    "START_PRICE": "Start Price",
    "BUY_NOW_PRICE_IF_APPLICABLE": "Buy now Price (if applicable)",
    "DEPOSIT_AMOUNT": "Deposit amount",
    "AUCTION_END_TIME": "Auction end time",
    "MIN_STEP": "Min Step",
    "MAX_STEP": "Max Step"
  },
  "ka": {
    "SALE_PERIOD": "გაყიდვის პერიოდი",
    "START_PRICE": "საწყისი ფასი",
    "BUY_NOW_PRICE_IF_APPLICABLE": "პირდაპირ ყიდვის ფასი (არასავალდებულო)",
    "DEPOSIT_AMOUNT": "დეპოზიტის რაოდენობა",
    "AUCTION_END_TIME": "აუქციონის დამთავრების დრო",
    "MIN_STEP": "მინ ბიჯი",
    "MAX_STEP": "მაქ ბიჯი"
  },
  "ru": {
    "SALE_PERIOD": "Период продажи",
    "START_PRICE": "Начальная цена",
    "BUY_NOW_PRICE_IF_APPLICABLE": "Цена Купить сейчас (если это применимо)",
    "DEPOSIT_AMOUNT": "Сумма депозита",
    "AUCTION_END_TIME": "Время окончания аукциона",
    "MIN_STEP": "Минимальный шаг",
    "MAX_STEP": "Максимальный шаг"
  },
  "uk": {
    "SALE_PERIOD": "Період продажу",
    "START_PRICE": "Початкова ціна",
    "BUY_NOW_PRICE_IF_APPLICABLE": "Ціна Купити зараз (якщо це може бути застосовано)",
    "DEPOSIT_AMOUNT": "Сума депозиту",
    "AUCTION_END_TIME": "Час закінчення аукціону",
    "MIN_STEP": "Мінімальний крок",
    "MAX_STEP": "Максимальний крок"
  }
}
</i18n>
