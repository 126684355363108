<template>
  <div class="add-car">
    <page-subnav>
      <template slot="right">
        <router-link
          class="add-car__tab"
          active-class="add-car__tab_active"
          :to="{ name: 'addCar', params: { auctionType: 'direct-sale' } }"
        >
          {{ $t('DIRECT_SALE_LINK') }}
        </router-link>
        <router-link
          class="add-car__tab"
          active-class="add-car__tab_active"
          :to="{ name: 'addCar', params: { auctionType: 'auction' } }"
        >
          {{ $t('AUCTION_LINK') }}
        </router-link>
      </template>
    </page-subnav>

    <div class="add-car__form">
      <ui-container class="add-car__form-content">
        <add-car-photos />
        <add-car-sale-info />
        <add-car-technical />
        <add-car-video />
        <add-car-description />

        <ui-button
          @click="submit"
          :is-disabled="isSubmitting"
        >
          {{ $t('SUBMIT_BTN') }}
        </ui-button>
      </ui-container>
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import AddCarPhotos from './components/AddCarPhotos'
import AddCarTechnical from './components/AddCarTechnical'
import AddCarDescription from './components/AddCarDescription'
import AddCarVideo from './components/AddCarVideo'
import AddCarSaleInfo from './components/AddCarSaleInfo'

import { UiContainer, UiButton } from '@shelf.network/ui-kit'

import { AUCTION_TYPES } from './store/constants'

import { mapActions, mapMutations } from 'vuex'
import { actions, mutations } from './store/types'
import { showError, showSuccess } from 'Utils/notifications'
import { scrollToError } from 'Utils/scrollTo'
import { i18n } from 'I18n/instance'

export default {
  name: 'add-car',

  components: {
    PageSubnav,
    UiContainer,
    UiButton,
    AddCarPhotos,
    AddCarTechnical,
    AddCarDescription,
    AddCarVideo,
    AddCarSaleInfo,
  },

  data: _ => ({
    isLoading: false,
    isSubmitting: false,
  }),

  watch: {
    '$route.params.auctionType': {
      immediate: true,
      handler () {
        this.updateAuctionType()
      },
    }
  },

  methods: {
    ...mapActions('ui/add-car', {
      createLot: actions.CREATE_LOT,
      validateForm: actions.VALIDATE_FORM,
      resetForm: actions.RESET_FORM,
    }),

    ...mapMutations('ui/add-car', {
      setFormField: mutations.SET_FORM_FIELD,
    }),

    updateAuctionType () {
      const value = this.$route.params.auctionType === 'auction'
        ? AUCTION_TYPES.eng
        : AUCTION_TYPES.sale
      this.setFormField({ field: 'auctionType', value })
    },

    async submit () {
      if (!await this.validateForm()) {
        scrollToError()
        return
      }

      this.isSubmitting = true
      try {
        await this.createLot()
        showSuccess(i18n.t('TOASTS.CREATE_LOT_MSG'))

        this.resetForm()
        this.updateAuctionType()
      } catch (e) {
        console.error(e)
        showError(i18n.t('TOASTS.CREATE_LOT_ERROR'))
      }
      this.isSubmitting = false
    },
  },

  metaInfo () {
    return {
      title: this.$t('META_TITLE'),
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
      meta: [
        {
          name: 'description',
          content: this.$t('META_DESCRIPTION'),
          vmid: 'description'
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.add-car {
  &__form-content {
    padding-top: 2em;
    width: 100%;
    max-width: 70rem;
    display: grid;
    grid-gap: 5em;
  }

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "HEADING": "To list your vehicle, we need some details:",
    "SUBHEADING": "Your vehicle will be subject of instant leasing and our financing partner will facilitate the match",
    "PROMOTED_SUCCESSFUL_TITLE": "THANKS!",
    "PROMOTED_SUCCESSFUL_MSG": "Your vehicle has been promoted and automatically approved",
    "META_TITLE": "Add A Car",
    "META_DESCRIPTION": "Upload your car for sale, your vehicle will be subject of instant leasing and our financing partner will facilitate the match for you.",
    "DIRECT_SALE_LINK": "DIRECT SALE",
    "AUCTION_LINK": "AUCTION",
    "SUBMIT_BTN": "Submit"
  },
  "ka": {
    "HEADING": "თქვენი ავტომობილის დასამატებლად შეავსეთ ველები:",
    "SUBHEADING": "შენი ავტომობილი გაიყიდება ლიზინგით და პოტენციურ მყიდველს დაუმტკიცდება დაფინანსება",
    "PROMOTED_SUCCESSFUL_TITLE": "ᲛᲐᲓᲚᲝᲑᲐ!",
    "PROMOTED_SUCCESSFUL_MSG": "თქვენი ვეტერინარი დაწინაურებულია\n და ავტომატურად დამტკიცებულია",
    "META_TITLE": "ატვირთვა",
    "META_DESCRIPTION": "ატვირთე ავტომობილი და დააჩქარე გაყიდვა. უფასო განცხადების განთავსება. ატვირთეთ მანქანის სურათები და თქვენს ავტომობილზე, მყიდველისთვის გავრცელდება ავტომატური ლიზინგი ჩვენი ფინანსური პარტნიორის მიერ.",
    "DIRECT_SALE_LINK": "გაყიდვა",
    "AUCTION_LINK": "აუქციონი",
    "SUBMIT_BTN": "შენახვა"
  },
  "ru": {
    "HEADING": "Добавить авто",
    "SUBHEADING": "Ваш автомобиль будет предметом мгновенного лизинга, и наш финансовый партнер будет содействовать проведению сделки.",
    "PROMOTED_SUCCESSFUL_TITLE": "СПАСИБО!",
    "PROMOTED_SUCCESSFUL_MSG": "Ваше авто продвинуто и автоматически подтверждено",
    "META_TITLE": "Добавить авто",
    "META_DESCRIPTION": "Выставляйте своё авто на продажу. Наши финансовые партнеры предоставят лизинг вашим покупутелям.",
    "DIRECT_SALE_LINK": "ПРЯМАЯ ПРОДАЖА",
    "AUCTION_LINK": "АУКЦИОН",
    "SUBMIT_BTN": "Отправить"
  },
  "uk": {
    "HEADING": "Додати авто",
    "SUBHEADING": "Ваш автомобіль буде предметом миттєвого лізингу, а наш партнер з фінансування сприятиме проведенню угоди",
    "PROMOTED_SUCCESSFUL_TITLE": "ДЯКУЄМО!",
    "PROMOTED_SUCCESSFUL_MSG": "Вашу автівку просунуто та автоматично підтверджено",
    "META_TITLE": "Додати авто",
    "META_DESCRIPTION": "Виставляйте своє авто на продаж. Наші финансові партнери нададуть лізинг вашим покупцям.",
    "DIRECT_SALE_LINK": "ПРЯМИЙ ПРОДАЖ",
    "AUCTION_LINK": "АУКЦІОН",
    "SUBMIT_BTN": "Відправити"
  }
}
</i18n>
