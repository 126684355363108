<template>
  <div
    class="photos-input"
    v-drop-files="{
      onDrop: onChange,
      highlightClass: 'photos-input_accept-drop'
    }"
    :data-drop-text="$t('DROP_OVERLAY')"
    :is-error="isError"
  >
    <template v-if="showAddButton">
      <div
        class="photos-input__uploader"
        :class="{ 'photos-input__uploader_absolute': isSelectedPhotos }"
      >
        <label
          class="photos-input__label"
          for="file-input"
        >
          <div class="photos-input__label-icon" />
          <span class="photos-input__label-text">
            {{ $t('SELECT_PHOTO') }}
          </span>
        </label>
        <input
          id="file-input"
          type="file"
          multiple
          accept="image/jpeg,image/png"
          class="photos-input__file-input"
          @change="onChange($event.target.files)"
          ref="fileInput"
        >
      </div>
    </template>
    <div
      class="photos-input__draggable"
      :class="{ 'photos-input__draggable_offset': showAddButton }"
      v-dragula="{
        items: photos,
        direction: isSmallScreen ? 'vertical' : 'horizontal',
        handlerClass: 'photos-input__container_draggable'
      }"
      @drop="setPhotos($event.detail)"
    >
      <div
        class="photos-input__container"
        v-for="(photo, index) in photos"
        :key="photo.id"
      >
        <photo
          class="photos-input__img"
          :photo="photo"
          @close="removePhoto"
          :is-cover="index === 0"
        />

        <div class="photos-input__container_draggable" />
      </div>
    </div>

    <template v-if="isError">
      <p class="photos-input__errors">
        <slot name="error" />
      </p>
    </template>
  </div>
</template>

<script>
import Photo from './Photo'
import dragula from './dragulaDirective'
import dropFiles from './dropFilesDirective'

import { viewport } from '@shelf.network/ui-kit'

import { mapGetters, mapActions } from 'vuex'
import { getters, actions } from '../../store/modules/photos/types'

export default {
  name: 'photos-input',
  components: { Photo },
  directives: { dropFiles, dragula },

  props: {
    isError: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters('ui/add-car/photos', {
      photos: getters.photos,
      showAddButton: getters.showAddPhotoButton,
    }),

    isSelectedPhotos () {
      return this.photos.length
    },

    isSmallScreen () {
      return viewport.isSmall
    },
  },

  watch: {
    photos: {
      handler (v) {
        this.$emit('input', v)
      },
      deep: true,
    }
  },

  methods: {
    ...mapActions('ui/add-car/photos', {
      uploadPhotos: actions.UPLOAD_PHOTOS,
      removePhoto: actions.REMOVE_PHOTO,
      setPhotos: actions.SET_PHOTOS,
    }),
    async onChange (files) {
      await this.uploadPhotos(Object.values(files))
    }
  }
}
</script>

<style lang="scss">
.gu-mirror {
  font-family: $layout-font-family;
}
</style>

<style scoped lang="scss">
@import "../../../../../node_modules/dragula/dist/dragula.css";

@mixin absolute-stretch {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.photos-input {
  @include respond(--item-margin, 15px, 15px, 15px, 10px);
  @include respond(--item-width, 100% / 5, 100% / 5, 100% / 5, 100%);
  @include respond(--item-height, 100% / 5, 100% / 5, 100% / 5, 31.25%);

  margin: calc(var(--item-margin) * -1) 0 0 calc(var(--item-margin) * -1);
  position: relative;

  &_accept-drop {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -8px;
      left: -8px;
      right: -8px;
      bottom: -8px;
      z-index: z-index(1);
      background: LightCyan;
      border-radius: 25px;
      border: 5px dashed steelBlue;
    }

    &::after {
      content: attr(data-drop-text);
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: z-index(1);
      font-size: 16px;
      font-weight: 700;
      color: steelBlue;
    }
  }

  &__container,
  &__uploader {
    display: block;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    margin: var(--item-margin) 0 0 var(--item-margin);
    flex: 0 1 calc(var(--item-width) - var(--item-margin));
    padding-bottom: calc(var(--item-height) - var(--item-margin));
  }

  &__uploader {
    box-shadow: inset 0 0 0 1px #9498a6; // instead of border to sync sizes
    border-radius: 5px;
    width: calc(var(--item-width) - var(--item-margin));

    &_absolute {
      position: absolute;
    }
  }

  &__container_draggable {
    @include absolute-stretch();

    width: 100%;
    height: 100%;
    cursor: move;
    touch-action: none;

    &:active {
      cursor: move;
    }

    @include respond-below(sm) {
      top: 0.4em;
      left: 0.4em;
      padding: 0.5em;
      width: 2.4em;
      height: 2.4em;
      background: $color-white;
      border-radius: 50%;
      /* stylelint-disable font-family-no-missing-generic-family-keyword */
      font-family: var(--ui-icon--font) !important;
      box-shadow: 0 0.6px 2.5px rgba($color-black, 0.12);

      &::after {
        content: "\e957";
        position: absolute;
        font-size: 1.35em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__img {
    @include absolute-stretch();

    &:hover {
      box-shadow: 0 0 0 2px #dd4e41;
    }
  }

  &__draggable {
    display: flex;
    flex-flow: row wrap;

    &:active {
      cursor: move;
    }

    & > .photos-input__container:first-child {
      box-shadow: 0 0 0 2px #dc4b42;
    }

    &_offset > .photos-input__container:first-child {
      @include respond-above(sm) {
        margin-left: calc(var(--item-width) + var(--item-margin));
      }

      @include respond-below(sm) {
        margin-top: calc(var(--item-height) + var(--item-margin));
      }
    }
  }

  &__file-input {
    opacity: 0;
    position: absolute;
    z-index: z-index(negative);
  }

  &__label {
    @include absolute-stretch();

    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond-below(sm) {
      flex-direction: row;
    }

    &-icon {
      width: 50px;
      height: 50px;
      background-color: #000;
      border-radius: 50%;
      position: relative;
      margin: 10px;

      @include respond-below(sm) {
        width: 30px;
        height: 30px;
        margin: 6px;
      }

      &::after,
      &::before {
        // black circle with white "+" in it
        display: block;
        content: "";
        width: 26px;
        height: 2.5px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #fff;
        border-radius: 5px;
        transform: translate(-50%, -50%) scale(0.5);

        @include respond-below(sm) {
          width: 20px;
          height: 2px;
        }
      }

      &::after {
        transform: translate(-50%, -50%) scale(0.5) rotate(-90deg);
      }
    }

    &-text {
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      padding: 4px 10px;
    }
  }

  &__errors {
    font-size: 16px;
    line-height: 1.25em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 0 0 0 var(--item-margin);
    color: $color-flag-is-error;
  }
}
</style>

<i18n>
{
  "en": {
    "DROP_OVERLAY": "Drop files",
    "SELECT_PHOTO": "Drag / Add Pictures"
  },
  "ka": {
    "DROP_OVERLAY": "ჩააგდეთ ფაილი",
    "SELECT_PHOTO": "დაამატეთ სურათი"
  },
  "ru": {
    "DROP_OVERLAY": "Отпустите файлы",
    "SELECT_PHOTO": "Добавить изображение"
  },
  "uk": {
    "DROP_OVERLAY": "Відпустіть файли",
    "SELECT_PHOTO": "Додати зображення"
  }
}
</i18n>
