<template>
  <section class="description-section">
    <div class="description-section__heading">
      <h4 class="add-car__section-heading">
        {{ $t('DESCRIPTION_SECTION_TITLE') }}
      </h4>

      <div class="description-section__tabs">
        <template v-for="locale in locales">
          <ui-button
            fill="none"
            :key="`tab-${locale}`"
            :class="{
              'description-section__tab': true,
              'description-section__tab_selected': selectedLocale === locale,
              'description-section__tab_error':
                Boolean(errors[`notesLocalized/${locale}`]),
            }"
            @click="selectedLocale = locale"
          >
            {{ $t(`TAB_${locale.toUpperCase()}_LBL`) }}
          </ui-button>
        </template>
      </div>
    </div>

    <div class="description-section__content">
      <template v-for="locale in locales">
        <ui-textarea
          v-if="selectedLocale === locale"
          :key="locale"
          :placeholder="$t(`DESCRIPTION_${locale.toUpperCase()}_LBL`)"
          :maxlength-soft="MAX_DESCRIPTION_LENGTH"
          :value="form[`notesLocalized/${locale}`]"
          :is-error="Boolean(errors[`notesLocalized/${locale}`])"
          @input="setNotesLocalized(locale, $event)"
          rows="10"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors[`notesLocalized/${locale}`]) }}
          </span>
        </ui-textarea>
      </template>
    </div>
  </section>
</template>

<script>
import { UiTextarea, UiButton } from '@shelf.network/ui-kit'
import { LOCALES } from 'Constants/locales'
import { config } from '@/config'

import { mapMutations, mapGetters } from 'vuex'
import { mutations, getters } from '../store/types'

import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

const MAX_DESCRIPTION_LENGTH = 1500

export default {
  name: 'add-car-description',
  components: {
    UiTextarea,
    UiButton,
  },
  data: _ => ({
    MAX_DESCRIPTION_LENGTH,
    selectedLocale: config.DEFAULT_LOCALE,
  }),
  computed: {
    ...mapGetters('ui/add-car', {
      form: getters.form,
      errors: getters.errors,
    }),
    locales () {
      return Object
        .values(LOCALES)
        .map(l => l.isoCode)
        .sort(l => l === config.DEFAULT_LOCALE.toLowerCase() ? -1 : 0)
    },
  },
  methods: {
    ...mapMutations('ui/add-car', {
      setFormField: mutations.SET_FORM_FIELD,
    }),
    setNotesLocalized (locale, value) {
      this.setFormField({ field: `notesLocalized/${locale}`, value })
    },

    jsvLocalizeError,
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/add-car-form";

.description-section {
  display: grid;
  grid-gap: 0.5em;

  &__heading {
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }

  &__tabs {
    display: flex;
    justify-content: flex-end;
  }

  &__tab {
    position: relative;

    &_selected {
      /deep/ .ui-button__button,
      /deep/ .ui-button__button:hover {
        color: $color-ui-primary;
      }

      &::after {
        content: "";
        display: block;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-ui-primary;
        height: 2px;
        width: calc(100% - 16px);
        position: absolute;
        transition: all 0.15s;
      }
    }

    &_error {
      /deep/ .ui-button__button {
        position: relative;

        &::after {
          content: '\e90a';
          /* stylelint-disable font-family-no-missing-generic-family-keyword */
          font-family: var(--ui-icon--font) !important;
          color: $color-ui-primary;
          position: absolute;
          top: 0;
          right: 10%;
        }

        &::before {
          content: "~~~~~~~~~~~~";
          font-size: 0.6em;
          font-weight: 900;
          color: $color-ui-primary;
          position: absolute;
          bottom: 0;
          width: 40%;
          left: 50%;
          transform: translateX(-50%);
          overflow: hidden;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DESCRIPTION_SECTION_TITLE": "Add a description:",
    "TAB_EN_LBL": "EN",
    "TAB_RU_LBL": "RU",
    "TAB_UK_LBL": "UK",
    "TAB_KA_LBL": "KA",
    "DESCRIPTION_EN_LBL": "Vehicle description in English",
    "DESCRIPTION_RU_LBL": "Vehicle description in Russian",
    "DESCRIPTION_UK_LBL": "Vehicle description in Ukrainian",
    "DESCRIPTION_KA_LBL": "Vehicle description in Georgian"
  },
  "ka": {
    "DESCRIPTION_SECTION_TITLE": "ᲓᲐᲛᲐᲢᲔᲑᲘᲗᲘ ᲛᲝᲜᲐᲪᲔᲛᲔᲑᲘ:",
    "TAB_EN_LBL": "ENG",
    "TAB_RU_LBL": "РУС",
    "TAB_UK_LBL": "UK",
    "TAB_KA_LBL": "ᲥᲐᲠᲗ",
    "DESCRIPTION_EN_LBL": "კომენტარები ინგლისურ ენაზე",
    "DESCRIPTION_RU_LBL": "კომენტარები რუსულ ენაზე",
    "DESCRIPTION_UK_LBL": "კომენტარები უკრაინულ ენაზე",
    "DESCRIPTION_KA_LBL": "კომენტარები ქართულ ენაზე"
  },
  "ru": {
    "DESCRIPTION_SECTION_TITLE": "Добавить описание:",
    "TAB_EN_LBL": "EN",
    "TAB_RU_LBL": "RU",
    "TAB_UK_LBL": "UK",
    "TAB_KA_LBL": "KA",
    "DESCRIPTION_EN_LBL": "Описание авто на английском",
    "DESCRIPTION_RU_LBL": "Описание авто на русском",
    "DESCRIPTION_UK_LBL": "Описание авто на украинском",
    "DESCRIPTION_KA_LBL": "Описание авто на грузинском"
  },
  "uk": {
    "DESCRIPTION_SECTION_TITLE": "Додати опис:",
    "TAB_EN_LBL": "EN",
    "TAB_RU_LBL": "RU",
    "TAB_UK_LBL": "UK",
    "TAB_KA_LBL": "KA",
    "DESCRIPTION_EN_LBL": "Опис авто англійською",
    "DESCRIPTION_RU_LBL": "Опис авто російською",
    "DESCRIPTION_UK_LBL": "Опис авто українською",
    "DESCRIPTION_KA_LBL": "Опис авто грузинською"
  }
}
</i18n>
