<template>
  <section class="add-car__section">
    <h4 class="add-car__section-heading">
      {{ $t('VIDEO_SECTION_TITLE') }}
    </h4>

    <div class="add-car__form-row">
      <ui-text
        class="add-car__form-field"
        :placeholder="$t('YOUTUBE_VIDEO_PH')"
        :value="form.youtubeLink"
        :is-error="Boolean(errors.youtubeLink)"
        @input="setFormField({
          field: 'youtubeLink',
          value: $event,
        })"
      >
        <ui-icon
          slot="dock-right"
          icon="logo-youtube"
          class="youtube-icon"
        />
        <span slot="error">
          {{ translatedLinkError }}
        </span>
      </ui-text>
    </div>
  </section>
</template>

<script>
import { UiText, UiIcon } from '@shelf.network/ui-kit'

import { mapMutations, mapGetters } from 'vuex'
import { mutations, getters } from '../store/types'

import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

export default {
  name: 'add-car-video',
  components: {
    UiText,
    UiIcon,
  },

  computed: {
    ...mapGetters('ui/add-car', {
      form: getters.form,
      errors: getters.errors,
    }),

    translatedLinkError () {
      return jsvLocalizeError(this.errors.youtubeLink)
    }
  },

  methods: {
    ...mapMutations('ui/add-car', {
      setFormField: mutations.SET_FORM_FIELD,
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/add-car-form";

.youtube-icon {
  position: relative;
  top: 1.5px;
  color: red;
}
</style>

<i18n>
{
  "en": {
    "VIDEO_SECTION_TITLE": "Add video:",
    "YOUTUBE_VIDEO_PH": "YouTube URL"
  },
  "ka": {
    "VIDEO_SECTION_TITLE": "ᲕᲘᲓᲔᲝᲡ ᲛᲘᲑᲛᲐ:",
    "YOUTUBE_VIDEO_PH": "ვიდეო ლინკი"
  },
  "ru": {
    "VIDEO_SECTION_TITLE": "Добавить видео:",
    "YOUTUBE_VIDEO_PH": "Ссылка YouTube"
  },
  "uk": {
    "VIDEO_SECTION_TITLE": "Додати відео:",
    "YOUTUBE_VIDEO_PH": "Посилання YouTube"
  }
}
</i18n>
