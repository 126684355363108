<template>
  <div
    :class="{
      'date-input': true,
      'date-input_error': isError,
    }"
  >
    <ui-dropdown
      select
      ref="dropdown"
      look="secondary"
    >
      <span>
        <template v-if="value">
          {{
            $fd(new Date(value),
                $t('DATE_FORMATS.MONTH_DAY_YEAR_HOURS_MINUTES')
            )
          }}
        </template>
        <template v-else>
          {{ placeholder }}
        </template>
      </span>
      <due-date-select
        slot="content"
        :value="value ? new Date(value) : getNullDate()"
        :is-error="isError"
        @apply="onApply"
      />
    </ui-dropdown>

    <span class="date-input__error-message">
      <slot
        v-if="isError"
        name="error"
      />
    </span>
  </div>
</template>

<script>
import { UiDropdown } from '@shelf.network/ui-kit'
import DueDateSelect from 'Common/DueDateSelect'
import { getNullDate } from 'Utils/dateHelpers'

export default {
  name: 'date-input',
  components: {
    UiDropdown,
    DueDateSelect,
  },
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    isError: { type: Boolean, default: false },
  },
  methods: {
    getNullDate,
    onApply (value) {
      this.$refs.dropdown.closeDropdown()
      this.$emit('input', value.toISOString())
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/add-car-form";

.date-input {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5em;

  /deep/ .ui-dropdown .ui-dropdown__content {
    padding: 1em;
    min-width: 0;
  }

  &_error {
    /deep/ .ui-dropdown__trigger_select-like[fill="frame"][look="secondary"] {
      border-color: $color-flag-is-error;
      border-style: dashed;
    }
  }

  &__error-message {
    color: $color-flag-is-error;
  }
}
</style>
