<template>
  <section class="add-car__section">
    <h4 class="add-car__section-heading">
      {{ $t('SALE_SECTION_TITLE') }}
    </h4>

    <div class="add-car__section-content">
      <div class="add-car__form-row">
        <location-input
          :value="form.cityId"
          class="add-car__form-field"
          :placeholder="$t('LOCATION_PH')"
          :is-error="Boolean(errors.country || errors.city)"
          @input="setLocation"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.country || errors.city) }}
          </span>
        </location-input>
      </div>
    </div>

    <direct-sale-params v-if="form.auctionType === AUCTION_TYPES.sale" />
    <eng-auction-params v-if="form.auctionType === AUCTION_TYPES.eng" />

    <div class="add-car__section-content">
      <div class="add-car__form-row">
        <ui-switch
          class="add-car__form-field"
          look="primary"
          :value="form.isRegistered"
          @input="setIsRegistered"
        >
          {{
            form.isRegistered ? $t('REGISTERED_LBL') : $t('NOT_REGISTERED_LBL')
          }}
        </ui-switch>
      </div>
    </div>
  </section>
</template>

<script>
import { UiSwitch } from '@shelf.network/ui-kit'

import LocationInput from './LocationInput'
import DirectSaleParams from './AddCarDirectSaleParams'
import EngAuctionParams from './AddCarEngAuctionParams'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getters, actions, mutations } from '../store/types'
import { userGetters } from 'Store/entities/User/types'
import { AUCTION_TYPES } from '../store/constants'

import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

export default {
  name: 'add-car-sale-info',
  components: {
    DirectSaleParams,
    EngAuctionParams,
    LocationInput,
    UiSwitch,
  },

  computed: {
    ...mapGetters('ui/add-car', {
      form: getters.form,
      errors: getters.errors,
    }),

    ...mapGetters('entities/user', {
      profile: userGetters.PROFILE,
    }),

    AUCTION_TYPES: () => AUCTION_TYPES,
  },

  created () {
    if (this.profile.cityId) {
      this.setLocation(this.profile.cityId)
    }
  },

  methods: {
    ...mapActions('ui/add-car', {
      setLocation: actions.SET_LOCATION
    }),

    ...mapMutations('ui/add-car', {
      setFormField: mutations.SET_FORM_FIELD,
      setIsRegistered: mutations.SET_IS_REGISTERED,
    }),

    jsvLocalizeError,
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/add-car-form";
</style>

<i18n>
{
  "en": {
    "SALE_SECTION_TITLE": "Sale Info",
    "LOCATION_PH": "Location",
    "DIRECT_SALE": "Direct Sale",
    "AUCTION": "Auction",
    "REGISTERED_LBL": "Registered",
    "NOT_REGISTERED_LBL": "Not registered"
  },
  "ka": {
    "SALE_SECTION_TITLE": "ᲒᲐᲡᲐᲧᲘᲓᲘ ᲘᲜᲤᲝᲠᲛᲐᲪᲘᲐ",
    "LOCATION_PH": "ლოკაცია",
    "DIRECT_SALE": "გაყიდვა",
    "AUCTION": "აუქციონი",
    "REGISTERED_LBL": "განბაჟებული",
    "NOT_REGISTERED_LBL": "განუბაჟებელი"
  },
  "ru": {
    "SALE_SECTION_TITLE": "Детали продажи",
    "LOCATION_PH": "Локация",
    "DIRECT_SALE": "Прямая продажа",
    "AUCTION": "Аукцион",
    "REGISTERED_LBL": "Зарегистрировано",
    "NOT_REGISTERED_LBL": "Не зарегистрировано"
  },
  "uk": {
    "SALE_SECTION_TITLE": "Деталі продажу",
    "LOCATION_PH": "Локація",
    "DIRECT_SALE": "Прямий продаж",
    "AUCTION": "Аукціон",
    "REGISTERED_LBL": "Зареєстровано",
    "NOT_REGISTERED_LBL": "Не зареєстровано"
  }
}
</i18n>
