<template>
  <div class="photo-wrapper">
    <p
      class="photo-wrapper__error-overlay"
      v-if="isError"
    >
      {{ $t('UNEXPECTED_ERR') }}
    </p>

    <p
      class="photo-wrapper__error-overlay"
      v-else-if="isTooBig"
    >
      {{ $t('TOO_BIG_ERR') }}
    </p>

    <p
      class="photo-wrapper__error-overlay"
      v-else-if="isInvalidFormat"
    >
      {{ $t('INVALID_FORMAT_ERR') }}
    </p>

    <p
      class="photo-wrapper__cover-badge"
      v-else-if="isCover"
    >
      {{ $t('COVER_BADGE_TXT') }}
    </p>

    <img
      v-if="photoUrl"
      :src="photoUrl"
      alt="Photo"
      :class="['photo-wrapper__img', {
        'photo-wrapper__img_pending': isPending,
        'photo-wrapper__img_error': isError,
      }]"
    >

    <ui-spinner
      v-if="isPending"
      class="photo-wrapper__img-loader"
      overlay
    />

    <button
      class="photo-wrapper__button"
      @click="$emit('close', photo.id)"
    >
      <img
        class="photo-wrapper__cross-icon"
        :src="closeIconSrc"
      >
    </button>
  </div>
</template>

<script>
import { UiSpinner } from '@shelf.network/ui-kit'
import { photoUploadingStatuses } from '../../store/modules/photos/constants'
import CloseIcon from 'Assets/icons/close_white.svg'

export default {
  name: 'photo',
  components: { UiSpinner },

  props: {
    photo: {
      type: Object,
      required: true,
      default: null
    },
    isCover: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    closeIconSrc () {
      return CloseIcon
    },
    photoUrl () {
      return this.photo.localThumbnailUrl || this.photo.localUrl ||
        this.photo.thumbnailUrl || this.photo.url
    },

    isPending () {
      return this.photo.status === photoUploadingStatuses.PENDING
    },

    isError () {
      return this.photo.status === photoUploadingStatuses.ERROR
    },

    isTooBig () {
      return this.photo.status === photoUploadingStatuses.TOO_BIG
    },

    isInvalidFormat () {
      return this.photo.status === photoUploadingStatuses.INVALID_FORMAT
    }
  }
}
</script>

<style scoped lang="scss">
.photo-wrapper {
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.6s;
    will-change: contents;

    &_pending {
      opacity: 0.4;
    }

    &_error {
      filter: blur(4px);
      opacity: 0.4;
    }

    &-loader {
      background-color: transparent;
    }
  }

  &__button {
    z-index: z-index(1);
    border: none;
    fill: $color-white;
    width: 15px;
    height: 15px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 3px;
    padding: 0;
    cursor: pointer;
  }

  &__cross-icon {
    height: 12px;
    width: auto;
  }

  %overlay {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    background: fade_out(#000, 0.7);
  }

  &__error-overlay {
    @extend %overlay;

    color: #ff3824;
  }

  &__cover-badge {
    position: absolute;
    top: 10px;
    right: 24px;
    background: rgba(#000, 0.6);
    font-size: 14px;
    line-height: 23px;
    font-weight: 500;
    color: $color-ui-primary;
    padding: 0 8px;
    border-radius: 30px;
    pointer-events: none;
  }
}
</style>

<i18n>
{
  "en": {
    "UNEXPECTED_ERR": "ERROR",
    "TOO_BIG_ERR": "Too big",
    "INVALID_FORMAT_ERR": "Invalid format",
    "COVER_BADGE_TXT": "Cover Image"
  },
  "ka": {
    "UNEXPECTED_ERR": "ᲨᲔᲪᲓᲝᲛᲐ",
    "TOO_BIG_ERR": "ფოტოს ზომა დიდია",
    "INVALID_FORMAT_ERR": "არასწორი ფორმატი",
    "COVER_BADGE_TXT": "პირველი ფოტო"
  },
  "ru": {
    "UNEXPECTED_ERR": "ОШИБКА",
    "TOO_BIG_ERR": "Слишком большой размер изображения",
    "INVALID_FORMAT_ERR": "Недопустимый формат",
    "COVER_BADGE_TXT": "Обложка"
  },
  "uk": {
    "UNEXPECTED_ERR": "ПОМИЛКА",
    "TOO_BIG_ERR": "Занадто великий розмiр зображення",
    "INVALID_FORMAT_ERR": "Неприпустимий формат",
    "COVER_BADGE_TXT": "Обкладинка"
  }
}
</i18n>
