<template>
  <div class="add-car__section">
    <h4 class="add-car__section-heading">
      {{ $t('PHOTOS_SECTION_TITLE') }}
    </h4>

    <div class="add-car__section-content">
      <photos-input
        :is-error="Boolean(errors.fullImgLinks)"
        @input="setImgLinks"
      >
        <span slot="error">
          {{ translatedLinksError }}
        </span>
      </photos-input>
    </div>
  </div>
</template>

<script>
import PhotosInput from './PhotosInput/PhotosInput'

import { mapGetters, mapMutations } from 'vuex'
import { getters, mutations } from '../store/types'

import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

const MAX_PHOTOS_COUNT = 20
const MIN_PHOTOS_COUNT = 3

export default {
  name: 'add-car-photos',
  components: {
    PhotosInput,
  },
  data: _ => ({
    MAX_PHOTOS_COUNT,
    MIN_PHOTOS_COUNT,
  }),
  computed: {
    ...mapGetters('ui/add-car', {
      errors: getters.errors,
    }),

    translatedLinksError () {
      return jsvLocalizeError(this.errors.fullImgLinks)
    }
  },
  methods: {
    ...mapMutations('ui/add-car', {
      setFormField: mutations.SET_FORM_FIELD,
    }),
    setImgLinks (imgs = []) {
      const validImages = imgs.filter(i => i.thumbnailUrl && i.url)

      const fullImgLinks = validImages.map(i => i.thumbnailUrl)
      const hdImgLinks = validImages.map(i => i.url)

      this.setFormField({ field: 'fullImgLinks', value: fullImgLinks })
      this.setFormField({ field: 'hdImgLinks', value: hdImgLinks })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/add-car-form";
</style>

<i18n>
{
  "en": {
    "PHOTOS_SECTION_TITLE": "Add photos:"
  },
  "ka": {
    "PHOTOS_SECTION_TITLE": "ᲤᲝᲢᲝᲔᲑᲘᲡ ᲐᲢᲕᲘᲠᲗᲕᲐ:"
  },
  "uk": {
    "PHOTOS_SECTION_TITLE": "Загрузить фото:"
  },
  "ru": {
    "PHOTOS_SECTION_TITLE": "Завантажити фото:"
  }
}
</i18n>
