var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"drop-files",rawName:"v-drop-files",value:({
    onDrop: _vm.onChange,
    highlightClass: 'photos-input_accept-drop'
  }),expression:"{\n    onDrop: onChange,\n    highlightClass: 'photos-input_accept-drop'\n  }"}],staticClass:"photos-input",attrs:{"data-drop-text":_vm.$t('DROP_OVERLAY'),"is-error":_vm.isError}},[(_vm.showAddButton)?[_c('div',{staticClass:"photos-input__uploader",class:{ 'photos-input__uploader_absolute': _vm.isSelectedPhotos }},[_c('label',{staticClass:"photos-input__label",attrs:{"for":"file-input"}},[_c('div',{staticClass:"photos-input__label-icon"}),_vm._v(" "),_c('span',{staticClass:"photos-input__label-text"},[_vm._v("\n          "+_vm._s(_vm.$t('SELECT_PHOTO'))+"\n        ")])]),_vm._v(" "),_c('input',{ref:"fileInput",staticClass:"photos-input__file-input",attrs:{"id":"file-input","type":"file","multiple":"","accept":"image/jpeg,image/png"},on:{"change":function($event){return _vm.onChange($event.target.files)}}})])]:_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"dragula",rawName:"v-dragula",value:({
      items: _vm.photos,
      direction: _vm.isSmallScreen ? 'vertical' : 'horizontal',
      handlerClass: 'photos-input__container_draggable'
    }),expression:"{\n      items: photos,\n      direction: isSmallScreen ? 'vertical' : 'horizontal',\n      handlerClass: 'photos-input__container_draggable'\n    }"}],staticClass:"photos-input__draggable",class:{ 'photos-input__draggable_offset': _vm.showAddButton },on:{"drop":function($event){return _vm.setPhotos($event.detail)}}},_vm._l((_vm.photos),function(photo,index){return _c('div',{key:photo.id,staticClass:"photos-input__container"},[_c('photo',{staticClass:"photos-input__img",attrs:{"photo":photo,"is-cover":index === 0},on:{"close":_vm.removePhoto}}),_vm._v(" "),_c('div',{staticClass:"photos-input__container_draggable"})],1)}),0),_vm._v(" "),(_vm.isError)?[_c('p',{staticClass:"photos-input__errors"},[_vm._t("error")],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }