import dragula from 'dragula'

export default {
  parseOptions (options) {
    return {
      items: options.items,
      direction: options.direction,
      handlerClass: options.handlerClass
    }
  },

  createDrake (element, def) {
    def.dragIndex = -1
    def.dropIndex = -1

    def.drake = dragula([element], {
      revertOnSpill: true,
      direction: def.opts.direction,
      moves: (el, container, handle) => {
        return handle.classList.contains(def.opts.handlerClass)
      }
    })

    def.drake.on('drag', el => {
      def.dragIndex = [...element.children].indexOf(el)
    })

    def.drake.on('drop', el => {
      def.dropIndex = [...element.children].indexOf(el)

      const result = def.opts.items.slice(0)
      const removedItems = result.splice(def.dragIndex, 1)
      result.splice(def.dropIndex, 0, removedItems[0])

      element.dispatchEvent(new CustomEvent('drop', { detail: result }))
    })
  },

  bind (element, { def, value }) {
    def.opts = def.parseOptions(value)
    def.createDrake(element, def)
  },

  update (element, { def, value }) {
    const { direction, handlerClass } = def.opts
    def.opts = def.parseOptions(value)

    const shouldUpdateDrake = direction !== def.opts.direction ||
      handlerClass !== def.opts.handlerClass

    if (shouldUpdateDrake) {
      def.drake.destroy()
      def.createDrake(element, def)
    }
  },

  unbind (_, { def }) {
    def.drake.destroy()
  }
}
